<template>
  <div>
    <v-btn class="rounded-lg" color="primary" depressed @click="open">
      <v-icon left>mdi-plus</v-icon>
      Thêm mới
    </v-btn>

    <v-dialog v-model="visible" :width="'800px'" max-width="90%">
      <v-card>
        <v-card-title>
          <div class="font-weight-bold">Thêm mới Linked Products</div>

          <v-spacer></v-spacer>

          <v-btn
            :loading="isLoading"
            color="primary"
            depressed
            @click="handleAdd"
            >Thêm
          </v-btn>
        </v-card-title>

        <v-form ref="formRef" lazy-validation>
          <div class="pa-4 mt-4">
            <div>
              <div class="font-weight-bold mb-2">Tên</div>

              <tp-text-field
                v-model="state.name"
                background-color="white"
                class="text-body-1"
                dense
                filled
                outlined
                placeholder="Nhập tên thư viện"
                single-line
                validate="required"
              ></tp-text-field>
            </div>

            <div>
              <div class="font-weight-bold mb-2">Products</div>

              <tp-autocomplete
                v-model="state.product_ids"
                :items="filteredProductItems"
                :search-input.sync="searchKey"
                chips
                class="tp-filter-autocomplete mt-4"
                clearable
                item-text="name"
                item-value="id"
                multiple
                no-data-text="Không có dữ liệu"
                placeholder="Tìm kiếm sản phẩm"
                single-line
              ></tp-autocomplete>
            </div>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { defineComponent, getCurrentInstance, reactive, ref } from "vue";
import { debouncedWatch } from "@vueuse/core";
import { useVisible } from "@thinkvn/composables/lib/useVisible";
import { useMutation } from "@thinkvn/composables/lib/useMutation";
import { useFetchData } from "@thinkvn/composables/lib/useFetchData";

import Service from "../../../service/linked-product.service";
import { showToastActionAlert } from "@/core/composables";

import ProductService from "@/modules/Goods/service/product.service";

const { getListProductsV2 } = ProductService;

export default defineComponent({
  setup(_, { emit }) {
    const context = getCurrentInstance().proxy;
    const { visible, open, close } = useVisible();
    const formRef = ref(null);
    const state = reactive({
      name: "",
      product_ids: []
    });

    const { mutate: handleAdd, isLoading } = useMutation(
      () => {
        return Service.addLinkedProduct(state);
      },
      {
        onValidate() {
          return formRef.value.validate();
        },
        onSuccess() {
          close();
          emit("created");
        },
        onError(e) {
          showToastActionAlert(context, {
            message: e?.response?.data || e.message
          });
        }
      }
    );

    const searchKey = ref("");
    const { data: filteredProductItems, fetch } = useFetchData(
      () => getListProductsV2({ search: searchKey.value }),
      {
        enabled: () => Boolean(searchKey.value),
        transform: response => response.data
      }
    );
    debouncedWatch(searchKey, fetch, { debounce: 300 });

    return {
      visible,
      open,
      close,
      state,
      formRef,
      handleAdd,
      isLoading,
      filteredProductItems,
      searchKey
    };
  }
});
</script>
