import Api from "@/core/apis";

export default {
  getLinkedProducts(params) {
    return Api().$post("/v2/linked-product", params);
  },
  getLinkedProduct(id) {
    return Api().$post("/v2/linked-product/" + id);
  },
  addLinkedProduct(params) {
    return Api().$post("v2/linked-product/add", params);
  },
  updateLinkedProduct(params) {
    return Api().$post("v2/linked-product/update", params);
  },
  deleteLinkedProduct(params) {
    return Api().$post("v2/linked-product/delete", params);
  }
};
