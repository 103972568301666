<template>
  <div class="d_flex">
    <div class="flex-grow-1 overflow-hidden">
      <div class="action mr-5 mb-4">
        <div class="action-row d-flex align-center justify-space-between">
          <div class="action-row--col flex-grow-1 pr-5"></div>

          <button-add-linked-product
            @created="fetch"
          ></button-add-linked-product>
        </div>
      </div>

      <div class="px-5">
        <v-data-table
          :headers="headers"
          :items="data"
          calculate-widths
          disable-pagination
          hide-default-footer
          item-key="id"
          loading-text="Đang tải dữ liệu"
          no-data-text="Không có dữ liệu"
          no-results-text="Không tìm thấy kết quả phù hợp"
        >
          <template v-slot:[`item.products`]="{ item }">
            <div v-for="product in item.products" :key="product.id">
              {{ product.name }}
            </div>
          </template>

          <template v-slot:[`item.action`]="{ item, index }">
            <div class="d-flex justify-center">
              <button-edit-linked-product
                v-model="data[index]"
              ></button-edit-linked-product>
              <v-btn color="red" small text @click.stop="onDelete(item)">
                Delete
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, defineComponent, getCurrentInstance } from "vue";
import { useFetchData } from "@thinkvn/composables/lib/useFetchData";
import Service from "../../service/linked-product.service";
import ButtonAddLinkedProduct from "./components/ButtonAddLinkedProduct.vue";
import { showModalAlertError } from "@/core/composables";
import ButtonEditLinkedProduct from "@/modules/Goods/pages/LinkedProduct/components/ButtonEditLinkedProduct.vue";

export default defineComponent({
  components: { ButtonEditLinkedProduct, ButtonAddLinkedProduct },
  setup() {
    const state = reactive({
      search: ""
    });

    const { data, fetch } = useFetchData(
      () => Service.getLinkedProducts(state),
      {
        transform: response => response.data
      }
    );

    fetch();

    const context = getCurrentInstance().proxy;
    const onDelete = item => {
      showModalAlertError(
        context,
        {
          title: `<span>Xóa linked product <strong>"${item.name}"</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?"
        },
        async () => {
          await Service.deleteLinkedProduct({ id: item.id });

          fetch();
        }
      );
    };

    return {
      data,
      fetch,
      onDelete
    };
  },
  data() {
    return {
      headers: [
        {
          text: "Tên nhóm",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: "Products",
          align: "start",
          sortable: false,
          value: "products"
        },
        {
          text: "Hành động",
          align: "center",
          sortable: false,
          value: "action",
          width: "200px"
        }
      ]
    };
  }
});
</script>
